import React, { useState, Fragment, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Collapse, CardBody, Card, Button, Modal, ModalBody } from 'reactstrap'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import Swiper from 'react-id-swiper'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useIntl, Link } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'

// Components
import Pagina from '../components/Pagina'
import BannerLogin from '../components/BannerLogin'

// CSS, SCSS
import './styles/coleta-de-oleo-lubrificante-usado.scss'

// Assets
import agua from '../images/coleta-de-oleo-lubrificante/icons/agua.svg'
import chamine from '../images/coleta-de-oleo-lubrificante/icons/chamine.svg'
import oleo from '../images/coleta-de-oleo-lubrificante/icons/oleo.svg'
import setaCurva from '../images/coleta-de-oleo-lubrificante/icons/Coleta_1.svg'
import fabrica from '../images/coleta-de-oleo-lubrificante/icons/Coleta_2.svg'
import consumidor from '../images/coleta-de-oleo-lubrificante/icons/consumidor.svg'
import residuos from '../images/coleta-de-oleo-lubrificante/icons/residuos.svg'
import reverso from '../images/coleta-de-oleo-lubrificante/icons/reverso.svg'
import wpp from '../images/coleta-de-oleo-lubrificante/icons/wpp.svg'
import icon1 from '../images/coleta-de-oleo-lubrificante/icons/caminhao.svg'
import icon2 from '../images/coleta-de-oleo-lubrificante/icons/check.svg'
import icon3 from '../images/coleta-de-oleo-lubrificante/icons/capacete.svg'
import icon4 from '../images/coleta-de-oleo-lubrificante/icons/mao.svg'
import icon5 from '../images/coleta-de-oleo-lubrificante/icons/selo.svg'
import icon6 from '../images/coleta-de-oleo-lubrificante/icons/recicla.svg'

const ModalColeta = () => {
  const [modal, setModal] = useState(true)
  if (typeof window !== 'undefined') {
    localStorage.setItem('vistoModal', '1')
  }
  const toggle = () => setModal(!modal)
  const intl = useIntl()

  return (
    <div>
      <Modal className="" centered isOpen={modal} modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 700 }}
        toggle={toggle}>
        <ModalBody className="d-flex flex-column align-items-center bg-lwart-lightblue2 p-lg-5 py-5">
          <Button className="btn-close" onClick={toggle}><span className="font-size-2">X</span></Button>
          <h2 className="text-lwart-green text-center line-height-normal">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.modal_coleta.title' }))}</h2>
          <p className="text-white text-center line-height-15 mb-4 font-size-125">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.modal_coleta.p.p1' }))}<br className="d-none d-lg-block" /> {parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.modal_coleta.p.p2' }))}<br className="d-none d-lg-block" /> {parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.modal_coleta.p.p3' }))}<br className="d-none d-lg-block" /> {parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.modal_coleta.p.p4' }))}<br className="d-none d-lg-block" /> {parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.modal_coleta.p.p5' }))}</p>
          <Link to="/area-cliente/criar-conta/"><Button className="bg-lwart-green3 text-white border-0 btn-modal font-Spinnaker" onClick={toggle}>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.modal_coleta.btn' }))}</Button></Link>
        </ModalBody>
      </Modal>
    </div>
  )
}

const SectionDuvidasLegislação = () => {
  const [isCollapse, setIsCollapse] = useState(false)
  const [isCollapse2, setIsCollapse2] = useState(false)
  const [isCollapse3, setIsCollapse3] = useState(false)
  const [isCollapse4, setIsCollapse4] = useState(false)
  const [isCollapse5, setIsCollapse5] = useState(false)
  const [isCollapse6, setIsCollapse6] = useState(false)
  const intl = useIntl()

  return (
    <Fragment>
      <section className="container-fluid px-0 mt-0">
        <h3 className="text-lwart-green mb-3 font-size-15">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_legislacao.title' }))}</h3>
        <div className="container px-0">
          <div>
            <div id="collapseMoodle" onClick={() => setIsCollapse(!isCollapse) || setIsCollapse2(false) || setIsCollapse3(false) || setIsCollapse4(false) || setIsCollapse5(false) || setIsCollapse6(false)} className={isCollapse === true ? 'collapseacionado mb-1' : 'collapseoff mb-1'}><div className="d-flex align-items-center"><div className="text-lwart-darkblue number d-flex justify-content-center align-items-center"><span className="d-inline-block py-2 font-size-25 font-Spinnaker">01</span></div><span className="ml-lg-5 ml-3 font-Spinnaker font-size-125 font-mobile-card pr-3 py-2">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_legislacao.01.pergunta' }))}</span></div></div>
            <Collapse isOpen={isCollapse}>
              <Card className="border-0 font-20px">
                <CardBody id="collapseText">
                  {parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_legislacao.01.resposta' }))}
                </CardBody>
              </Card>
            </Collapse>
          </div>
          <div>
            <div id="collapseMoodle" onClick={() => setIsCollapse2(!isCollapse2) || setIsCollapse(false) || setIsCollapse3(false) || setIsCollapse4(false) || setIsCollapse5(false) || setIsCollapse6(false)} className={isCollapse2 === true ? 'collapseacionado mb-1' : 'collapseoff mb-1'}><div className="d-flex align-items-center"><div className="text-lwart-darkblue number d-flex justify-content-center align-items-center"><span className="d-inline-block py-2 font-size-25 font-Spinnaker">02</span></div><span className="ml-lg-5 ml-3 font-Spinnaker font-size-125 font-mobile-card pr-3 py-2">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_legislacao.02.pergunta' }))}</span></div></div>
            <Collapse isOpen={isCollapse2}>
              <Card className="border-0 font-20px">
                <CardBody id="collapseText">
                  <ul>
                    <li>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_legislacao.02.resposta.l1' }))}</li>
                    <li>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_legislacao.02.resposta.l2' }))}</li>
                    <li>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_legislacao.02.resposta.l3' }))}</li>
                    <li>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_legislacao.02.resposta.l4' }))}</li>
                    <li>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_legislacao.02.resposta.l5' }))}</li>
                    <li>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_legislacao.02.resposta.l6' }))}</li>
                  </ul>
                </CardBody>
              </Card>
            </Collapse>
          </div>
          <div>
            <div id="collapseMoodle" onClick={() => setIsCollapse3(!isCollapse3) || setIsCollapse(false) || setIsCollapse2(false) || setIsCollapse4(false) || setIsCollapse5(false) || setIsCollapse6(false)} className={isCollapse3 === true ? 'collapseacionado mb-1' : 'collapseoff mb-1'}><div className="d-flex align-items-center"><div className="text-lwart-darkblue number d-flex justify-content-center align-items-center"><span className="d-inline-block py-2 font-size-25 font-Spinnaker">03</span></div><span className="ml-lg-5 ml-3 font-Spinnaker font-size-125 font-mobile-card pr-3 py-2">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_legislacao.03.pergunta' }))}</span></div></div>
            <Collapse isOpen={isCollapse3}>
              <Card className="border-0 font-20px">
                <CardBody id="collapseText">
                  {parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_legislacao.03.resposta.title' }))}
                  <ul>
                    <li>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_legislacao.03.resposta.l1' }))} <a target='blank' href="mailto:coleta@lwart.com.br">coleta@lwart.com.br</a></li>
                    <li>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_legislacao.03.resposta.l2' }))} 0800 701 0088</li>
                  </ul>
                </CardBody>
              </Card>
            </Collapse>
          </div>
          <div>
            <div id="collapseMoodle" onClick={() => setIsCollapse4(!isCollapse4) || setIsCollapse2(false) || setIsCollapse3(false) || setIsCollapse(false) || setIsCollapse5(false) || setIsCollapse6(false)} className={isCollapse4 === true ? 'collapseacionado mb-1' : 'collapseoff mb-1'}><div className="d-flex align-items-center"><div className="text-lwart-darkblue number d-flex justify-content-center align-items-center"><span className="d-inline-block py-2 font-size-25 font-Spinnaker">04</span></div><span className="ml-lg-5 ml-3 font-Spinnaker font-size-125 font-mobile-card pr-3 py-2">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_legislacao.04.pergunta' }))}</span></div></div>
            <Collapse isOpen={isCollapse4}>
              <Card className="border-0 font-20px">
                <CardBody id="collapseText">
                  {parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_legislacao.04.resposta' }))}
                </CardBody>
              </Card>
            </Collapse>
          </div>
          <div>
            <div id="collapseMoodle" onClick={() => setIsCollapse5(!isCollapse5) || setIsCollapse2(false) || setIsCollapse3(false) || setIsCollapse(false) || setIsCollapse4(false) || setIsCollapse6(false)} className={isCollapse5 === true ? 'collapseacionado mb-1' : 'collapseoff mb-1'}><div className="d-flex align-items-center"><div className="text-lwart-darkblue number d-flex justify-content-center align-items-center"><span className="d-inline-block py-2 font-size-25 font-Spinnaker">05</span></div><span className="ml-lg-5 ml-3 font-Spinnaker font-size-125 font-mobile-card pr-3 py-2">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_legislacao.05.pergunta' }))}</span></div></div>
            <Collapse isOpen={isCollapse5}>
              <Card className="border-0 font-20px">
                <CardBody id="collapseText">
                  {parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_legislacao.05.resposta' }))}<a target='blank' href="mailto:operacoesdecoleta@lwart.com.br"> operacoesdecoleta@lwart.com.br</a>.
                </CardBody>
              </Card>
            </Collapse>
          </div>
          <div>
            <div id="collapseMoodle" onClick={() => setIsCollapse6(!isCollapse6) || setIsCollapse2(false) || setIsCollapse3(false) || setIsCollapse(false) || setIsCollapse4(false) || setIsCollapse5(false)} className={isCollapse6 === true ? 'collapseacionado mb-1' : 'collapseoff mb-1'}><div className="d-flex align-items-center"><div className="text-lwart-darkblue number d-flex justify-content-center align-items-center"><span className="d-inline-block py-2 font-size-25 font-Spinnaker">06</span></div><span className="ml-lg-5 ml-3 font-Spinnaker font-size-125 font-mobile-card pr-3 py-2">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_legislacao.06.pergunta' }))}</span></div></div>
            <Collapse isOpen={isCollapse6}>
              <Card className="border-0 font-20px">
                <CardBody id="collapseText">
                  {parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_legislacao.06.resposta' }))} <a target='blank' href="https://www.confaz.fazenda.gov.br/legislacao/convenios/2000/CV038_00">https://www.confaz.fazenda.gov.br/legislacao/convenios/2000/CV038_00</a>
                </CardBody>
              </Card>
            </Collapse>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

const SectionDuvidasOperacional = () => {
  const [isCollapse, setIsCollapse] = useState(false)
  const [isCollapse2, setIsCollapse2] = useState(false)
  const [isCollapse3, setIsCollapse3] = useState(false)
  const [isCollapse4, setIsCollapse4] = useState(false)
  const [isCollapse5, setIsCollapse5] = useState(false)
  const [isCollapse6, setIsCollapse6] = useState(false)
  const intl = useIntl()

  return (
    <Fragment>
      <section className="container-fluid px-0 m-0 mb-5">
        <h3 className="text-lwart-green mb-3 font-size-15">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.title' }))}</h3>
        <div className="container px-0">
          <div>
            <div id="collapseMoodle" onClick={() => setIsCollapse(!isCollapse) || setIsCollapse2(false) || setIsCollapse3(false) || setIsCollapse4(false) || setIsCollapse5(false) || setIsCollapse6(false)} className={isCollapse === true ? 'collapseacionado mb-1' : 'collapseoff mb-1'}><div className="d-flex align-items-center"><div className="text-lwart-darkblue number d-flex justify-content-center align-items-center"><span className="d-inline-block py-2 font-size-25 font-Spinnaker">01</span></div><span className="ml-lg-5 ml-3 font-Spinnaker font-size-125 font-mobile-card pr-3 py-2">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.01.pergunta' }))}</span></div></div>
            <Collapse isOpen={isCollapse}>
              <Card className="border-0">
                <CardBody id="collapseText">
                  <ul>
                    <li>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.01.resposta.l1' }))}<br /><a href="https://app.anp.gov.br/anp-cpl-web/public/simp/consulta-empresas-autorizadas/consulta.xhtml" target="blank"> https://app.anp.gov.br/anp-cpl-web/public/simp/consulta-empresas-autorizadas/consulta.xhtml</a></li>
                    <li>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.01.resposta.l2' }))}<br /><a href="http://www.anp.gov.br/distribuicao-e-revenda/lubrificantes/relacao-de-agentes-autorizados" target="blank">http://www.anp.gov.br/distribuicao-e-revenda/lubrificantes/relacao-de-agentes-autorizados</a></li>
                  </ul>
                </CardBody>
              </Card>
            </Collapse>
          </div>
          <div>
            <div id="collapseMoodle" onClick={() => setIsCollapse2(!isCollapse2) || setIsCollapse(false) || setIsCollapse3(false) || setIsCollapse4(false) || setIsCollapse5(false) || setIsCollapse6(false)} className={isCollapse2 === true ? 'collapseacionado mb-1' : 'collapseoff mb-1'}><div className="d-flex align-items-center"><div className="text-lwart-darkblue number d-flex justify-content-center align-items-center"><span className="d-inline-block py-2 font-size-25 font-Spinnaker">02</span></div><span className="ml-lg-5 ml-3 font-Spinnaker font-size-125 font-mobile-card pr-3 py-2">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.02.pergunta' }))}</span></div></div>
            <Collapse isOpen={isCollapse2}>
              <Card className="border-0">
                <CardBody id="collapseText">
                  {parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.02.resposta.title' }))}
                  <ul>
                    <li>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.02.resposta.l1' }))} <a target='blank' href="mailto:coleta@lwart.com.br">coleta@lwart.com.br</a></li>
                    <li>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.02.resposta.l2' }))} 0800 701 0088</li>
                    <li>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.02.resposta.l3' }))} <a href="www.lwart.com.br">www.lwart.com.br</a></li>
                  </ul>
                </CardBody>
              </Card>
            </Collapse>
          </div>
          <div>
            <div id="collapseMoodle" onClick={() => setIsCollapse3(!isCollapse3) || setIsCollapse(false) || setIsCollapse2(false) || setIsCollapse4(false) || setIsCollapse5(false) || setIsCollapse6(false)} className={isCollapse3 === true ? 'collapseacionado mb-1' : 'collapseoff mb-1'}><div className="d-flex align-items-center"><div className="text-lwart-darkblue number d-flex justify-content-center align-items-center"><span className="d-inline-block py-2 font-size-25 font-Spinnaker">03</span></div><span className="ml-lg-5 ml-3 font-Spinnaker font-size-125 font-mobile-card pr-3 py-2">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.03.pergunta' }))}</span></div></div>
            <Collapse isOpen={isCollapse3}>
              <Card className="border-0">
                <CardBody id="collapseText">
                  {parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.03.resposta' }))}
                </CardBody>
              </Card>
            </Collapse>
          </div>
          <div>
            <div id="collapseMoodle" onClick={() => setIsCollapse4(!isCollapse4) || setIsCollapse2(false) || setIsCollapse3(false) || setIsCollapse(false) || setIsCollapse5(false) || setIsCollapse6(false)} className={isCollapse4 === true ? 'collapseacionado mb-1' : 'collapseoff mb-1'}><div className="d-flex align-items-center"><div className="text-lwart-darkblue number d-flex justify-content-center align-items-center"><span className="d-inline-block py-2 font-size-25 font-Spinnaker">04</span></div><span className="ml-lg-5 ml-3 font-Spinnaker font-size-125 font-mobile-card pr-3 py-2">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.04.pergunta' }))}</span></div></div>
            <Collapse isOpen={isCollapse4}>
              <Card className="border-0">
                <CardBody id="collapseText">
                  {parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.04.resposta' }))}

                </CardBody>
              </Card>
            </Collapse>
          </div>
          <div>
            <div id="collapseMoodle" onClick={() => setIsCollapse5(!isCollapse5) || setIsCollapse2(false) || setIsCollapse3(false) || setIsCollapse(false) || setIsCollapse4(false) || setIsCollapse6(false)} className={isCollapse5 === true ? 'collapseacionado mb-1' : 'collapseoff mb-1'}><div className="d-flex align-items-center"><div className="text-lwart-darkblue number d-flex justify-content-center align-items-center"><span className="d-inline-block py-2 font-size-25 font-Spinnaker">05</span></div><span className="ml-lg-5 ml-3 font-Spinnaker font-size-125 font-mobile-card pr-3 py-2">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.05.pergunta' }))}</span></div></div>
            <Collapse isOpen={isCollapse5}>
              <Card className="border-0">
                <CardBody id="collapseText">
                  {parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.05.resposta' }))}

                </CardBody>
              </Card>
            </Collapse>
          </div>
          <div>
            <div id="collapseMoodle" onClick={() => setIsCollapse6(!isCollapse6) || setIsCollapse2(false) || setIsCollapse3(false) || setIsCollapse(false) || setIsCollapse4(false) || setIsCollapse5(false)} className={isCollapse6 === true ? 'collapseacionado mb-1' : 'collapseoff mb-1'}><div className="d-flex align-items-center"><div className="text-lwart-darkblue number d-flex justify-content-center align-items-center"><span className="d-inline-block py-2 font-size-25 font-Spinnaker">06</span></div><span className="ml-lg-5 ml-3 font-Spinnaker font-size-125 font-mobile-card pr-3 py-2">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.06.pergunta' }))}</span></div></div>
            <Collapse isOpen={isCollapse6}>
              <Card className="border-0">
                <CardBody id="collapseText">
                  {parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.06.title' }))}

                  <ul>
                    <li>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.06.resposta.l1' }))}</li>
                    <li>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.06.resposta.l2' }))}</li>
                    <li>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.06.resposta.l3' }))}</li>
                    <li>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.06.resposta.l4' }))}</li>
                    <li>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.06.resposta.l5' }))}</li>
                    <li>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.06.resposta.l6' }))}</li>
                    <li>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.06.resposta.l7' }))}</li>
                    <li>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.06.resposta.l8' }))}</li>
                    <li>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.06.resposta.l9' }))}</li>
                    <li>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.06.resposta.l10' }))}</li>
                  </ul>
                  {parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.faq_operacionais.06.p1' }))}
                </CardBody>
              </Card>
            </Collapse>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

const CarouseFeedBack = () => {
  const intl = useIntl()

  const params = {
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    breakpoints: {
      1000: {
        slidesPerView: 2,
        spaceBetween: 80
      },
      1100: {
        slidesPerView: 2,
        spaceBetween: 80
      },
      1200: {
        slidesPerView: 2,
        spaceBetween: 80
      }
    }
  }
  return (
    <>
      <h2 className="text-left text-white ml-lg-5 pl-lg-3">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.carrousel_feedback.title' }))}</h2>
      <div id="carousel-feedback">
        <Swiper {...params}>
          <div className="d-flex flex-column pl-lg-3">
            <p className="font-Spinnaker text-white line-height-normal letter-space-normal">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.carrousel_feedback.feedback1.p1' }))}</p>
            <p className="font-Spinnaker text-white line-height-normal letter-space-normal">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.carrousel_feedback.feedback1.p2' }))}</p>
            <span className="font-Spinnaker text-lwart-green line-height-normal letter-space-normal">Gleydson Barboza da Mota</span>
            <span className="font-Spinnaker text-lwart-green line-height-normal letter-space-normal">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.carrousel_feedback.feedback1.span_profissao' }))}</span>
          </div>
          <div className="d-flex flex-column pr-lg-3">
            <p className="font-Spinnaker text-white line-height-normal letter-space-normal">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.carrousel_feedback.feedback2.p1' }))}</p>
            <p className="font-Spinnaker text-white line-height-normal letter-space-normal">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.carrousel_feedback.feedback2.p2' }))}</p>
            <span className="font-Spinnaker text-lwart-green line-height-normal letter-space-normal">Plínio Márcio de Satana Faria</span>
            <span className="font-Spinnaker text-lwart-green line-height-normal letter-space-normal">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.carrousel_feedback.feedback2.span_profissao' }))}</span>
            <span className="font-Spinnaker text-lwart-green line-height-normal letter-space-normal">Óleo Bahia LTDA ME</span>
          </div>
          <div className="d-flex flex-column pr-lg-3">
            <p className="font-Spinnaker text-white line-height-normal letter-space-normal">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.carrousel_feedback.feedback3.p1' }))}</p>
            <p className="font-Spinnaker text-white line-height-normal letter-space-normal">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.carrousel_feedback.feedback3.p2' }))}</p>
            <p className="font-Spinnaker text-white line-height-normal letter-space-normal">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.carrousel_feedback.feedback3.p3' }))}</p>
            <span className="font-Spinnaker text-lwart-green line-height-normal letter-space-normal">Gabriel Aragão</span>
            <span className="font-Spinnaker text-lwart-green line-height-normal letter-space-normal">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.carrousel_feedback.feedback3.span_profissao' }))}</span>
            <span className="font-Spinnaker text-lwart-green line-height-normal letter-space-normal">Grupo Fornecedora</span>
          </div>
          <div className="d-flex flex-column pr-lg-3">
            <p className="font-Spinnaker text-white line-height-normal letter-space-normal">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.carrousel_feedback.feedback4.p1' }))}</p>
            <p className="font-Spinnaker text-white line-height-normal letter-space-normal">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.carrousel_feedback.feedback4.p2' }))}</p>
            <span className="font-Spinnaker text-lwart-green line-height-normal letter-space-normal">Olga Souza</span>
            <span className="font-Spinnaker text-lwart-green line-height-normal letter-space-normal">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.carrousel_feedback.feedback4.span_profissao' }))}</span>
            <span className="font-Spinnaker text-lwart-green line-height-normal letter-space-normal">ADTSA divisão de concessionárias</span>
          </div>
        </Swiper>
      </div>
    </>
  )
}

const FloatingWpp = () => {
  const intl = useIntl()

  return (
    <>
      {intl.locale === 'pt'
        ? <a href='https://api.whatsapp.com/send?phone=5508007010088' target="_blank" rel="noopener noreferrer" className='buttomWpp'>
          <div className='green'>
            <img src={wpp} />
          </div>
          <div className='textGreen'>
            <p className='text-white'>{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.floating_wpp.p' })}</p>
          </div>
        </a>
        : ''
      }
    </>

  )
}

const MoldeDesempenho = props => (
  <>
    <div className="cardIconGreen col-lg-auto col-auto mt-4 mb-lg-4 mb-0">
      <div className="text-lg-center d-flex align-items-center">
        <img src={props.icon} alt="Icone do Card" className="w-100" />
      </div>
    </div>
    <div className="col-lg-6 col-auto mt-lg-4 mt-2 mb-lg-4 d-flex align-items-center justify-content-lg-start justify-content-center p-0 m-0">
      <p className="text-lwart-blue font-Spinnaker pl-lg-2 pl-lg-2 line-height-1 mb-0">{props.text}</p>
    </div>
  </>
)

const NossaEtica = ({ conduta, pdf }) => {
  const intl = useIntl()

  return <>
    <section className="full m-0 pt-5 mx-0">
      <div className="container py-md-5 px-md-5 px-0">
        <div className="row d-flex px-0 px-md-4 mx-0 mx-md-2 justify-content-center">
          <div className="col-lg-3 col-md-4 col-7 p-0 mt-md-0">
            <div className='d-flex align-items-end justify-content-end'>
              <GatsbyImage image={conduta} />
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-5 mt-lg-5 px-0 px-md-3 pr-2">
            <h3 className='text-lwart-blue line-height-normal letter-space-normal'>{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.nossa_etica.title' })}</h3>
            <p className='line-height-normal letter-space-normal'>{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.nossa_etica.p1' })}</p>
            <a href={pdf} download target="_blank" rel="noreferrer noopener"><button className='bg-lwart-darkblue btn-conduta text-white'>{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.nossa_etica.btn1' })}</button></a>
          </div>
        </div>
      </div>
    </section>
  </>
}

const NossoCompromisso = ({ caminhao }) => {
  const [showMore, setShowMore] = useState()

  const ShowMore = (id) => {
    setShowMore(id)
  }
  const intl = useIntl()

  return <>
    <div className="bg-blue-gradient-left menu-coleta-de-oleo py-3 d-none d-lg-block">
      <ul className="d-flex flex-column flex-md-row m-0 justify-content-center">
        <li className="px-5 font-Spinnaker"><Link to="#beneficios">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.nosso_compromisso.lista.l1' })}</Link></li>
        <li className="px-5 font-Spinnaker"><Link to="#meio-ambiente">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.nosso_compromisso.lista.l2' })}</Link></li>
        <li className="px-5 font-Spinnaker"><Link to="#licencas">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.nosso_compromisso.lista.l3' })}</Link></li>
        <li className="px-5 font-Spinnaker"><Link to="#centros-de-coleta">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.nosso_compromisso.lista.l4' })}</Link></li>
        <li className="px-4 font-Spinnaker"><Link to="#perguntas-frequentes">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.nosso_compromisso.lista.l5' })}</Link></li>
      </ul>
    </div>
    <section className="full m-0 py-5 mx-0">
      <div className="container py-lg-5 px-lg-5 px-0">
        <div className="row px-4 px-lg-4 mx-0 mx-lg-2 justify-content-between">
          <div className="col-lg-8 p-0 mt-5 mt-lg-0 order-2 order-lg-1">
            <div>
              <div className="float-icon-sustentabilidade">
                <img src={setaCurva} className="rounded-circle bg-lwart-blue" alt="Seta Curva" />
              </div>
              <GatsbyImage image={caminhao} className="image-card-oleo-lubrificante" />
            </div>
          </div>
          <div className="col-lg-4 mt-lg-3 px-0 px-lg-3 mb-5 order-1 order-lg-2">
            <h2 className="text-lwart-blue mb-3 text-left letter-space-normal">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.nosso_compromisso.title' }))}</h2>
            <p className="line-height-normal">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.nosso_compromisso.p1' }))}
            </p>
            <p className="line-height-normal letter-space-normal">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.nosso_compromisso.p2' }))}
            </p>
            <p className="line-height-normal letter-space-normal">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.nosso_compromisso.p3' }))}
            </p>
          </div>
        </div>
        <div className='d-flex justify-content-end p-0 position-relative'>
          <div className='position-absolute NossoCompromissoInfo' onMouseEnter={() => ShowMore('logisticaReversa')} onMouseLeave={() => ShowMore()}>
            <p className='text-white text-center'>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.nosso_compromisso.p4' }))}</p>
          </div>
          {showMore === 'logisticaReversa' &&
            <div className='position-absolute text-center NossoCompromissoInfoHover'>
              <img src={reverso} />
              <p className='text-white pt-1'>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.nosso_compromisso.p5' }))}
              </p>
            </div>
          }
        </div>
      </div>
    </section>
  </>
}

const Beneficios = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  const intl = useIntl()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <motion.section ref={ref} animate={controls} id="beneficios" className="full overflow-auto m-0 bg-banner-beneficios py-5 d-flex align-items-center">
      <div className="container p-lg-4 px-0">
        <div className="row px-4 mx-0 px-lg-4 mx-lg-2 align-items-center justify-content-center">
          <div className="p-0 mb-5 mb-lg-5">
            <h2 className="text-white text-center mb-3">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.beneficios.title1' }))}</h2>
          </div>
          <motion.div initial="hidden"
            transition={{ duration: 1.5, delay: 0.5 }}
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: 100 }
            }} className="col-lg-4 p-0 mb-5 mb-lg-0">
            <div className="float-icon-sustentabilidade">
              <img src={residuos} className="rounded-circle bg-lwart-lightblue2" />
            </div>
            <div className="card-beneficios bg-lwart-darkblue p-4 py-5 mx-auto">
              <h3 className="text-white font-size-15 text-nowrap letter-space-normal mt-4 mb-4 px-2">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.beneficios.title2' }))}</h3>
              <p className="text-white font-size-1 line-height-normal pl-2 pr-4 mb-4">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.beneficios.p1' }))}
              </p>
              <p className="text-white font-size-1 line-height-normal pl-2 pr-4 mb-4">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.beneficios.p2' }))}
              </p>
            </div>
          </motion.div>
          <motion.div initial="hidden"
            transition={{ duration: 1.5, delay: 0.5 }}
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: 100 }
            }} className="col-lg-4 p-0 mb-5 mb-lg-0">
            <div>
              <div className="float-icon-sustentabilidade">
                <img src={fabrica} className="rounded-circle bg-lwart-green3" alt="Imagem Planeta" />
              </div>
              <div className="card-beneficios bg-lwart-blue p-4 py-5 mx-auto">
                <h3 className="text-white font-size-16 text-nowrap letter-space-normal mt-4 mb-4 px-2">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.beneficios.title3' }))}</h3>
                <p className="text-white font-size-1 line-height-normal pl-2 pr-4 mb-4">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.beneficios.p3' }))}
                </p>
                <p className="text-white font-size-1 line-height-normal pl-2 pr-4 mb-4">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.beneficios.p4' }))}</p>
              </div>
            </div>
          </motion.div>
          <motion.div initial="hidden"
            transition={{ duration: 1.5, delay: 0.5 }}
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: 100 }
            }} className="col-lg-4 p-0">
            <div>
              <div className="float-icon-sustentabilidade">
                <img src={consumidor} className="rounded-circle bg-lwart-darkblue" alt="Imagem Planeta" />
              </div>
              <div className="card-beneficios bg-green-perso p-4 py-5 mx-auto">
                <h3 className="text-white font-size-16 text-nowrap letter-space-normal mt-4 mb-4 px-2">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.beneficios.title4' }))}</h3>
                <p className="text-white font-size-1 line-height-normal pl-2 pr-4 mb-4">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.beneficios.p5' }))}
                </p>
                <p className="text-white font-size-1 line-height-normal pl-2 pr-4 mb-4">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.beneficios.p6' }))}
                </p>
                <p className="text-white font-size-1 line-height-normal pl-2 pr-4 mb-4">{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.beneficios.p7' }))}</p>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </motion.section>
  )
}

const MeioAmbiente = () => {
  const intl = useIntl()

  return (
    <section id="meio-ambiente" className="full m-0 py-lg-5 pt-5 pb-0">
      <div className="container px-0">
        <div className="row px-4 mx-lg-2 m-0 mb-2">
          <div className="col-12">
            <h2 className="text-lwart-blue">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.meio_ambiente.title' })}</h2>
          </div>
        </div>
        <div className="row px-4 mlg-2 mx-0 mb-4 justify-content-between">
          <div className="col-lg-4 p-0 d-flex flex-column align-items-center px-lg-4">
            <div className="border-gray" />
            <img className="iconSabia2 z-index-10" src={chamine} style={{ marginBottom: '32px' }} />
            <p className="line-height-normal font-Spinnaker">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.meio_ambiente.queima.p' })} <span className="text-lwart-lightblue2 font-Spinnaker">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.meio_ambiente.queima.span' })}</span></p>
          </div>
          <div className="col-lg-4 p-0 d-flex flex-column align-items-center justify-content-between px-lg-4">
            <div className="border-gray" />
            <img className="iconSabia1 mb-1 z-index-10" src={agua} />
            <p className="line-height-normal font-Spinnaker">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.meio_ambiente.agua.p1' })} <span className="text-lwart-lightblue2 font-Spinnaker">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.meio_ambiente.agua.span' })}</span>, {intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.meio_ambiente.agua.p2' })}</p>
          </div>
          <div className="col-lg-4 p-0 d-flex flex-column align-items-center justify-content-between px-lg-4">
            <div className="border-gray" />
            <img className="iconSabia3 z-index-10" src={oleo} style={{ marginBottom: '32px' }} />
            <p className="line-height-normal font-Spinnaker">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.meio_ambiente.oleo.p1' })}<span className="text-lwart-lightblue2 font-Spinnaker">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.meio_ambiente.oleo.span' })}</span>, {intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.meio_ambiente.oleo.p2' })}</p>
          </div>
        </div>
        <div className="row px-lg-4 mx-lg-2 mx-0">
          <div className="bg-lwart-green3 w-100 py-3 px-4 px-lg-0 d-flex justify-content-center">
            <h5 className="text-lwart-darkblue mb-0 textnaodeixe">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.meio_ambiente.solicite_agora.title1' })} <Link className="text-lwart-darkblue text-underline font-Spinnaker" to="#">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.meio_ambiente.solicite_agora.link' })}</Link> {intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.meio_ambiente.solicite_agora.title2' })}</h5>
          </div>
        </div>
      </div>
    </section>
  )
}

const LicencasCredenciais = () => {
  const intl = useIntl()

  return (
    <>
      <section id="licencas" className="full bg-licencas m-0 d-flex justify-content-end">
        <div className="container">
          <div className="row h-100">
            <div className="col-lg-6 offset-lg-6 h-100">
              <div className="bg-lwart-blue p-5 d-none d-lg-flex h-100 align-items-center">
                <div className="col-12">
                  <h2 className="text-white mb-4">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.licencas_credenciais.title' })}</h2>
                  <p className="text-white pr-2 line-height-normal">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.licencas_credenciais.p1' })}</p>
                  <p className="text-white pr-2 line-height-normal">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.licencas_credenciais.p2' })}</p>
                  <p className="text-white pr-2 line-height-normal">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.licencas_credenciais.p3' })}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

const Diferenciais = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  const intl = useIntl()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <motion.div ref={ref} animate={controls} className="container overflow-auto pt-5 pr-lg-5 pl-lg-5 px-4 pb-5 pb-0">
      <div className="row m-0 p-0 h-100 align-items-center">
        <div className="col-lg-4 text-white align-items-center px-0 px-lg-3">
          <motion.h2 initial="hidden"
            transition={{ duration: 1, delay: 0.5 }}
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: -50 }
            }} className="text-lwart-blue pb-3">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.diferenciais.porque_lwart.title' })}</motion.h2>
          <motion.div initial="hidden"
            transition={{ duration: 1.5, delay: 0.5 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }}>
            <p className="line-height-normal">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.diferenciais.porque_lwart.p1' })}</p>
            <p className="line-height-normal">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.diferenciais.porque_lwart.p2' })}</p>
            <p className="font-weight-bold line-height-normal text-lwart-blue mb-0">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.diferenciais.porque_lwart.p3' })}</p>
          </motion.div>
        </div>
        <div className="col-lg-8 d-flex px-0 px-lg-3">
          <div className="row justify-content-center align-items-center">
            <motion.div initial="hidden"
              transition={{ duration: 1, delay: 0.5 }}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: 50 }
              }} className="col-lg-6 col-6 d-lg-flex d-block">
              <MoldeDesempenho icon={icon1} text={<>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.diferenciais.icones.molde1' }))}</>} />
            </motion.div>
            <motion.div initial="hidden"
              transition={{ duration: 1, delay: 0.5 }}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: 50 }
              }} className="col-lg-6 col-6 d-lg-flex d-block">
              <MoldeDesempenho icon={icon2} text={<> {parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.diferenciais.icones.molde2' }))} </>} />
            </motion.div>
            <motion.div initial="hidden"
              transition={{ duration: 1, delay: 0.5 }}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: 50 }
              }} className="col-lg-6 col-6 d-lg-flex d-block margin-bottom-custom">
              <MoldeDesempenho icon={icon3} text={<> {parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.diferenciais.icones.molde3' }))} </>} />
            </motion.div>
            <motion.div initial="hidden"
              transition={{ duration: 1, delay: 0.5 }}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: 50 }
              }} className="col-lg-6 col-6 d-lg-flex d-block">
              <MoldeDesempenho icon={icon4} text={<> {parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.diferenciais.icones.molde4' }))} </>} />
            </motion.div>
            <motion.div initial="hidden"
              transition={{ duration: 1, delay: 0.5 }}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: 50 }
              }} className="col-lg-6 col-6 d-lg-flex d-block">
              <MoldeDesempenho icon={icon5} text={<> {parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.diferenciais.icones.molde5' }))} </>} />
            </motion.div>
            <motion.div initial="hidden"
              transition={{ duration: 1, delay: 0.5 }}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: 50 }
              }} className="col-lg-6 col-6 d-lg-flex d-block margin-bottom-customOleo">
              <MoldeDesempenho icon={icon6} text={<> {parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.diferenciais.icones.molde6' }))} </>} />
            </motion.div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

const CentrosColeta = ({ mapa, mapaEnglish, mapaEspanhol }) => {
  const intl = useIntl()

  return (
    <section id="centros-de-coleta" className="full color-mapa m-0 pt-lg-5">
      <div className="container pt-5 pr-lg-5 pl-lg-5 px-4 pb-5 pb-0">
        <div className="row m-0 p-0">
          <div className="col-lg-3 order-2 order-lg-1 pl-lg-3 p-0 mb-4">
            <h2 className="text-lwart-green text-nowrap mb-3 d-none d-lg-block">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.centros_coleta.title' })}</h2>
            <span className='font-size-12 mb-3  text-white font-Spinnaker d-block d-lg-none'>
              {parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.centros_coleta.title' }))}
            </span>
            <p className="text-white line-height-normal letter-space-normal text-nowrap mb-1">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.centros_coleta.p1' })} <span className="font-weight-bold">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.centros_coleta.span1' })}</span></p>
            <p className="text-white line-height-normal letter-space-normal text-nowrap font-weight-bold mb-1">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.centros_coleta.p2' })}</p>
            <p className="text-white line-height-normal letter-space-normal text-nowrap mb-4">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.centros_coleta.p3' })}</p>
            <span className="text-white letter-space-normal line-height-15 font-size-08"><span className="text-lwart-green">1.</span> Lençóis Paulista - SP</span><br />
            <span className="text-white letter-space-normal line-height-15 font-size-08"><span className="text-lwart-lightblue2">2.</span> São José do Rio Preto - SP</span><br />
            <span className="text-white letter-space-normal line-height-15 font-size-08"><span className="text-lwart-lightblue2">3.</span> Osasco - SP</span><br />
            <span className="text-white letter-space-normal line-height-15 font-size-08"><span className="text-lwart-lightblue2">4.</span> Goiânia - GO</span><br />
            <span className="text-white letter-space-normal line-height-15 font-size-08"><span className="text-lwart-lightblue2">5.</span> Belo Horizonte - MG</span><br />
            <span className="text-white letter-space-normal line-height-15 font-size-08"><span className="text-lwart-lightblue2">6.</span> Duque de Caxias - RJ</span><br />
            <span className="text-white letter-space-normal line-height-15 font-size-08"><span className="text-lwart-lightblue2">7.</span> Linhares - ES</span><br />
            <span className="text-white letter-space-normal line-height-15 font-size-08"><span className="text-lwart-lightblue2">8.</span> Feira de Santana - BA</span><br />
            <span className="text-white letter-space-normal line-height-15 font-size-08"><span className="text-lwart-lightblue2">9.</span> Maringá - PR </span><br />
            <span className="text-white letter-space-normal line-height-15 font-size-08"><span className="text-lwart-lightblue2">10.</span> Cascavel - PR</span><br />
            <span className="text-white letter-space-normal line-height-15 font-size-08"><span className="text-lwart-lightblue2">11.</span> Curitiba - PR</span><br />
            <span className="text-white letter-space-normal line-height-15 font-size-08"><span className="text-lwart-lightblue2">12.</span> Canoas - RS</span><br />
            <span className="text-white letter-space-normal line-height-15 font-size-08"><span className="text-lwart-lightblue2">13.</span> Campo Grande - MS</span><br />
            <span className="text-white letter-space-normal line-height-15 font-size-08"><span className="text-lwart-lightblue2">14.</span> Cuiabá - MT</span><br />
            <span className="text-white letter-space-normal line-height-15 font-size-08"><span className="text-lwart-lightblue2">15.</span> Belém - PA</span><br />
            <span className="text-white letter-space-normal line-height-15 font-size-08"><span className="text-lwart-lightblue2">16.</span> Recife - PE</span><br />
            <span className="text-white letter-space-normal line-height-15 font-size-08"><span className="text-lwart-lightblue2">17.</span> Fortaleza - CE</span><br />
            <span className="text-white letter-space-normal line-height-15 font-size-08"><span className="text-lwart-lightblue2">18.</span> Florianópolis - SC</span><br />
            <span className="text-white letter-space-normal line-height-15 font-size-08"><span className="text-lwart-lightblue2">19.</span> Porto Velho - RO</span>
          </div>
          <div className="col-lg order-1 order-lg-2 w-100-2 p-0 pt-lg-4 mt-5 ml-5">
            {intl.locale === 'pt'
              ? <GatsbyImage image={mapa} className="imageMapa mt-5 mt-lg-0" />
              : intl.locale === 'en'
                ? <GatsbyImage image={mapaEnglish} className="imageMapa mt-5 mt-lg-0" />
                : <GatsbyImage image={mapaEspanhol} className="imageMapa mt-5 mt-lg-0" />
            }
          </div>
        </div>
      </div>
    </section>
  )
}
const ColetaDeOleoLubrificante = ({ data }) => {
  // eslint-disable-next-line no-unused-vars
  const intl = useIntl()

  return <>
    <Pagina pagina={data.pagina} />
    {typeof window !== 'undefined' && localStorage.getItem('vistoModal') !== '1' && <ModalColeta />}

    <BannerLogin
      desktop={data.paralax.childImageSharp.gatsbyImageData}
      mobile={data.paralaxMobile.childImageSharp.gatsbyImageData}
      className="coletaOleo"
      paralax="paralaxImageOleoUsado"
      titulo={<>{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.banner_login.title' })}</>}
      texto={<>{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.banner_login.texto' })}</>}
      disk={<>{parse(intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.banner_login.disk' }))}</>}
      cor="blue"
      path="/area-cliente/solicite-sua-coleta/coleta-de-oleo/"
      originPath="/coleta-de-oleo-lubrificante-usado/"
    />
    <FloatingWpp />
    <NossoCompromisso caminhao={data.caminhao.childImageSharp.gatsbyImageData} />
    <Beneficios />
    <MeioAmbiente />
    <LicencasCredenciais />
    <Diferenciais />
    <CentrosColeta mapa={data.mapa.childImageSharp.gatsbyImageData} mapaEnglish={data.mapaEnglish.childImageSharp.gatsbyImageData} mapaEspanhol={data.mapaEspanhol.childImageSharp.gatsbyImageData} />
    <section className="full m-0 bg-lwart-lightblue2">
      <div className="container py-5">
        <div className="row">
          <div className="col px-4 p-lg-0">
            <CarouseFeedBack />
          </div>
        </div>
      </div>
    </section>
    <NossaEtica conduta={data.conduta.childImageSharp.gatsbyImageData} pdf={data.arquivo.arquivo.file.url} />
    <section id="perguntas-frequentes" className="full pt-5 pb-lg-0 m-0">
      <div className="container px-lg-5 px-0">
        <div className="row px-4 mx-lg-2 mx-0">
          <h2 className="text-lwart-blue mb-3">{intl.formatMessage({ id: 'paginas.coleta_de_oleo_lubrificante_usado.section_coleta.title' })}</h2>
        </div>
        <div className="row px-4 mx-lg-2 mx-0">
          <SectionDuvidasLegislação />
          <SectionDuvidasOperacional />
        </div>
      </div>
    </section>
    <div className="gradient-blue-to-green"></div>
  </>
}

export default ColetaDeOleoLubrificante

export const query = graphql`{
  arquivo: contentfulDocumentos(categoria: {eq: "coleta-de-oleo-lubrificante-usado"}) {
    arquivo {
      file {
        url
      }
    }
  }
  pagina: contentfulPagina(path: {eq: "/coleta-de-oleo-lubrificante-usado/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  paralax: file(
    relativePath: {eq: "coleta-de-oleo-lubrificante/imagens/banner.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
  paralaxMobile: file(
    relativePath: {eq: "coleta-de-oleo-lubrificante/imagens/coleta-oleo.png"}
  ) {
    childImageSharp {
      gatsbyImageData(
        quality: 100
        width: 400
        height: 500
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  caminhao: file(
    relativePath: {eq: "coleta-de-oleo-lubrificante/imagens/caminhֶo2.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
  conduta: file(
    relativePath: {eq: "coleta-de-oleo-lubrificante/imagens/codigoConduta.jpeg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 960, height: 1268, layout: CONSTRAINED)
    }
  }
  mapa: file(
    relativePath: {eq: "coleta-de-oleo-lubrificante/ilustracoes/mapa.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
  mapaEnglish: file(
    relativePath: {eq: "coleta-de-oleo-lubrificante/ilustracoes/mapa-english.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
  mapaEspanhol: file(
    relativePath: {eq: "coleta-de-oleo-lubrificante/ilustracoes/mapa-espanhol.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
}
`
