/* eslint-disable space-before-function-paren */
import React from 'react'
import {
  faFacebook,
  faLinkedinIn,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
import insta from '../../images/icon/instafooter.svg'
import { graphql, useStaticQuery } from 'gatsby'
import Logo from '../../images/icon/logo1-branco.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as styles from './footer.module.scss'
import { useIntl, Link } from 'gatsby-plugin-react-intl'

export default function Footer() {
  const data = useStaticQuery(
    graphql`
    query {
      arquivo:contentfulDocumentos(categoria: {eq: "politica-gestao"}) {
        arquivo {
          file {
            url
          }
        }
      }
    }
  `)
  const date = new Date()
  const year = date.getFullYear()
  const intl = useIntl()

  return (
    <>
      <footer className="bg-lwart-darkblue">
        <div className="container d-lg-block d-none">
          <div className="row py-4">
            <div className="col-md-4 d-flex justify-content-center">
              <img src={Logo} className="w-65 h-90" alt="Logo Lwart em Branco" />
            </div>
            <div className="col-md-4 d-flex align-items-center justify-content-center">
              <ul className="mb-0">
                <li><Link to="/nossa-essencia/" className={styles.ancoraFooter}>{intl.formatMessage({ id: 'components.footer.link1' })}</Link></li>
                {/* <li><Link to="/nossas-solucoes/" className={styles.ancoraFooter}>{intl.formatMessage({ id: 'components.footer.link2' })}</Link></li> */}
                <li><Link to="/coleta-de-oleo-lubrificante-usado/" className={styles.ancoraFooter}>{intl.formatMessage({ id: 'components.footer.link3' })}</Link></li>
                <li><Link to="/oleos-de-alta-performance/" className={styles.ancoraFooter}>{intl.formatMessage({ id: 'components.footer.link4' })}</Link></li>
                {/* <li><Link to="/gestao-de-residuos/" className={styles.ancoraFooter}>{intl.formatMessage({ id: 'components.footer.link5' })}</Link></li> */}
                <li><Link to="/sustentabilidade/" className={styles.ancoraFooter}>{intl.formatMessage({ id: 'components.footer.link6' })}</Link></li>
                {
                  intl.locale === 'pt'
                    ? <>
                      <li><Link to="/informacoes-financeiras/" className={styles.ancoraFooter}>Relacionamento com Investidor</Link></li>
                      <li><Link to="/fornecedores/" className={styles.ancoraFooter}>{intl.formatMessage({ id: 'components.footer.link10' })}</Link></li>
                      <li><Link to="/projeto-h/" className={styles.ancoraFooter}>Projeto H+</Link></li>
                    </>
                    : ''
                }
              </ul>
            </div>
            <div className="col-md-4 d-flex align-items-center justify-content-center">
              <ul className="mb-0">
                {intl.locale === 'pt' &&
                  <li><Link to="/blog/" className={styles.ancoraFooter}>{intl.formatMessage({ id: 'components.footer.link7' })}</Link></li>
                }
                <li><Link to="/gente/" className={styles.ancoraFooter}>{intl.formatMessage({ id: 'components.footer.link8' })}</Link></li>
                <li><Link to="/fale-com-a-gente/" className={styles.ancoraFooter}>{intl.formatMessage({ id: 'components.footer.link9' })}</Link></li>
                <li><a href={data.arquivo.arquivo.file.url} target="_blank" rel="noreferrer noopener" className={styles.ancoraFooter}>{intl.formatMessage({ id: 'components.footer.link11' })}</a></li>
                <li><Link to="/politica-de-privacidade/" className={styles.ancoraFooter}>{intl.formatMessage({ id: 'components.footer.link12' })}</Link></li>
                <li><Link to="/lei-lgpd/" className={styles.ancoraFooter}>{intl.formatMessage({ id: 'components.footer.link13' })}</Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="gradient-blue-to-green pt-3 pb-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-10 d-flex align-items-center">
                <p className="m-0 p-0 text-center text-white font-Spinnaker font-weight-bold letter-space-normal" id={styles.fontCreditos}>© <span id={styles.fontCreditos} className="font-Spinnaker">{year}</span> {intl.formatMessage({ id: 'components.footer.copyright' })}</p>
              </div>
              <div className="col-lg-4 col-1"></div>
              <div className="col-lg-4 col-1 d-flex justify-content-end">
                <a href="https://www.instagram.com/lwartsolucoesambientais/" target="blank" aria-label="Link Linkedin">
                  <img
                    src={insta}
                    className={styles.svgIcon}
                    alt="Instagram da Lwart Soluções Ambientais."
                  />
                </a>
                <a href="https://www.facebook.com/lwartsolucoesambientais/" target="blank" aria-label="Link Facebook">
                  <FontAwesomeIcon
                    icon={faFacebook}
                    className={styles.svgIcon}
                    alt="Facebook da Lwart Soluções Ambientais."
                  />
                </a>
                <a href="https://www.linkedin.com/company/lwartsolucoesambientais" target="blank" aria-label="Link Linkedin">
                  <FontAwesomeIcon
                    icon={faLinkedinIn}
                    className={styles.svgIcon}
                    alt="Linkedin da Lwart Soluções Ambientais."
                  />
                </a>
                <a href="https://www.youtube.com/user/grupolwart" target="blank" aria-label="Link Youtube">
                  <FontAwesomeIcon
                    icon={faYoutube}
                    className={styles.svgIcon}
                    alt="Youtube da Lwart Soluções Ambientais."
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
