import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Modal, Button } from 'reactstrap'
import { useIntl } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'

// Components
import BannerLwart from '../components/BannerLwart'
import Pagina from '../components/Pagina'

// CSS, SCSS
import './styles/fale-conosco.scss'

// Assets
import Fale1 from '../images/fale-conosco/telefone.svg'
import Fale2 from '../images/fale-conosco/email.svg'
import Fale3 from '../images/fale-conosco/redes-sociais.svg'
import Fale4 from '../images/fale-conosco/caminhaoFale.svg'
import Fale8 from '../images/fale-conosco/fale-conosco.svg'
import Fale9 from '../images/fale-conosco/assessoria-imprensa.svg'
// import gestaoResiduos from '../images/fale-conosco/gestao-residuos.svg'
import oleoBasico from '../images/fale-conosco/oleos-basicos.svg'
import fornecedor from '../images/fale-conosco/seja-fornecedor.svg'
import instagram from '../images/fale-conosco/insta.svg'
import facebook from '../images/fale-conosco/facebook.svg'
import linkedin from '../images/fale-conosco/linkedin.svg'
import youtube from '../images/fale-conosco/youtube.svg'
import whatsapp from '../images/fale-conosco/wpp.svg'
import instagramMobile from '../images/fale-conosco/iconInsta.svg'
import facebookMobile from '../images/fale-conosco/iconFace.svg'
import youtubeMobile from '../images/fale-conosco/iconYt.svg'
import linkedinMobile from '../images/fale-conosco/001-linkedin-br.svg'

const ModalFale = (props) => {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  return (
    <div>
      <div onClick={toggle} className="col p-1 position-relative">
        <div className="buttonContatoMobile">
          <div className='floatWpp'>
            <a href='https://api.whatsapp.com/send?phone=5508007010088' target="_blank" rel="noopener noreferrer">
              <img src={props.wpp} className='w-70' />
            </a>
          </div>
          <div className="h-100 align-items-center">
            <div className="p-2 w-100 h-100">
              <div className="d-flex justify-content-left align-items-center h-100">
                <div className="container">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-12 d-flex justify-content-center">
                      <img src={`${props.icone}`} style={{ width: '60px', height: '40px' }} />
                    </div>
                    <div className="col-12 d-flex justify-content-center p-0">
                      <p className="font-size-08 d-flex align-items-center line-height-normal text-lwart-blue text-center mt-lg-1 mt-2 px-lg-2 font-Spinnaker mb-0">{props.texto}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} className="mt-5 py-4 px-0">
        <Button className="btn-close-fala" onClick={toggle}><span className="font-size-2">x</span></Button>
        <div className="buttonContato buttonContatoModal mb-0">
          <div className="row justify-content-center align-items-center h-100 mt-5">
            <div className="col-4 h-100 pr-0">
              <div className="d-flex justify-content-end align-items-center h-100 p-0">
                <img src={`${props.icone}`} className="iconeContato w-90" />
              </div>
            </div>
            <div className="col-4 d-flex justify-content-center align-items-center h-100">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="my-auto font-size-12 text-lwart-blue font-Spinnaker mb-0">{props.texto}</div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center my-5">
            <div className="d-flex flex-column text-lwart-blue">{props.info}</div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const Molde = props => (
  <>
    <div className="col position-relative">
      <div className="buttonContato">
        <div className='floatWpp'>
          <a href='https://api.whatsapp.com/send?phone=5508007010088' target="_blank" rel="noopener noreferrer">
            <img src={props.wpp} />
          </a>
        </div>
        <div className="row h-100 p-2">
          <div className="col-3 h-100 pr-0 ml-3">
            <div className="d-flex justify-content-center align-items-center h-100 p-0">
              <img src={`${props.icone}`} className={`${props.width}`} />
            </div>
          </div>
          <div className="col-8 h-100 pr-0 pl-1">
            <div className="d-flex align-items-center h-100 pl-2">
              <div className="font-size-12 line-height-normal text-lwart-blue font-Spinnaker mb-0">{props.texto}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

const RedeSocialMobile = () => {
  return (
    <>
      <div className="info bg-linkedin">
        <a href="https://www.linkedin.com/company/lwartsolucoesambientais" target="blank" className="text-white font-size-15 text-decoration-none"><img src={linkedinMobile} />  LinkedIn</a>
      </div>
      <div className="info bg-facebook">
        <a href="https://www.facebook.com/lwartsolucoesambientais/" target="blank" className="text-white font-size-15 text-decoration-none"><img src={facebookMobile} />  Facebook</a>
      </div>
      <div className="info bg-youtube">
        <a href="https://www.youtube.com/user/grupolwart" target="blank" className="text-white font-size-15 text-decoration-none"><img src={youtubeMobile} />  Youtube</a>
      </div>
      <div className="info bg-instagram">
        <a href="https://www.instagram.com/lwartsolucoesambientais/" target="blank" className="text-white font-size-15 text-decoration-none"><img src={instagramMobile} />  Instagram</a>
      </div>
    </>
  )
}

const FaleConosco = ({ data }) => {
  const intl = useIntl()

  return <>
    <Pagina pagina={data.pagina} />
    <section className="full m-0 p-0">
      <BannerLwart
        desktop={data.paralax.childImageSharp.gatsbyImageData}
        mobile={data.paralaxMobile.childImageSharp.gatsbyImageData}
        titulo={<>{parse(intl.formatMessage({ id: 'paginas.fale_com_a_gente.banner_lwart.title' }))}</>}
        texto={<>{parse(intl.formatMessage({ id: 'paginas.fale_com_a_gente.banner_lwart.texto' }))}</>}
        className="paralaxImage banner-fale"
      />
      <div className="container d-none d-lg-block">
        <div className="row row-cols-lg-3 row-cols-1 mt-5 mb-5 m-0">
          <Molde width="w-100" wpp={whatsapp} link="#" icone={Fale4} texto={<><span className="font-size-1125 font-Spinnaker">{parse(intl.formatMessage({ id: 'paginas.fale_com_a_gente.molde.molde1' }))}<br />0800 701 0088<div className="text-lwart-blue font-Spinnaker font-size-08 line-height-20px"><a href="mailto:coleta@lwart.com.br" className="text-lwart-blue font-Spinnaker font-size-08 line-height-1">coleta@lwart.com.br</a></div></span></>} />
          {/* <Molde width="w-85" link="#" icone={gestaoResiduos} texto={<span className="font-size-1125 font-Spinnaker">{parse(intl.formatMessage({ id: 'paginas.fale_com_a_gente.molde.molde2' }))}<br />0800 701 0088 <a className="text-lwart-blue font-Spinnaker font-size-08" href="mailto:gestaoderesiduos@lwart.com.br">gestaoderesiduos@lwart.com.br</a></span>} /> */}
          <Molde width="w-85" link="#" icone={oleoBasico} texto={<span className="font-size-1125 font-Spinnaker">{parse(intl.formatMessage({ id: 'paginas.fale_com_a_gente.molde.molde3' }))}<br /><a className="text-lwart-blue font-size-08" href="mailto:comercial@lwart.com.br">comercial@lwart.com.br</a></span>} />
          <Molde width="w-85" link="#" icone={fornecedor} texto={<><span className="font-size-1125 font-Spinnaker">{parse(intl.formatMessage({ id: 'paginas.fale_com_a_gente.molde.molde4' }))}</span> <br /> <span className="font-size-08"><a className="text-lwart-blue font-Spinnaker" href="mailto:compraslwart@lwart.com.br">compraslwart@lwart.com.br</a></span> </>} />
          <Molde width="w-85" link="#" icone={Fale9} texto={<><span className="font-size-1125 font-Spinnaker">{parse(intl.formatMessage({ id: 'paginas.fale_com_a_gente.molde.molde5' }))}<br />(11) 95081-7522</span><span className="font-size-08 font-Spinnaker"><br /><a className="text-lwart-blue font-Spinnaker" href="mailto:lwart@pressaporter.com.br">lwart@pressaporter.com.br</a></span></>} />
          <Molde width="w-85" link="#" icone={Fale2} texto={<><span className="font-size-1125 font-Spinnaker">{parse(intl.formatMessage({ id: 'paginas.fale_com_a_gente.molde.molde6' }))}</span><br /> <p className="line-height-normal mb-0"><span className="pr-3 font-size-08 font-Spinnaker text-lwart-blue">Rod. Juliano Lorenzetti, 9 km<br /> + 270 m - Distrito Empresarial<br /> Luiz Trecenti - CEP 18685-900<br /> Lençóis Paulista - SP</span></p> </>} />
          <Molde width="w-85" link="#" icone={Fale1} texto={<><span className="font-size-1125 font-Spinnaker">{parse(intl.formatMessage({ id: 'paginas.fale_com_a_gente.molde.molde7' }))}</span><br />(14) 3269-5000 </>} />
          <Molde width="w-85" link="#" icone={Fale8} texto={<><span className="font-size-1125 font-Spinnaker">{parse(intl.formatMessage({ id: 'paginas.fale_com_a_gente.molde.molde8' }))}</span><br /> <span className="font-size-08"><a className="text-lwart-blue font-Spinnaker" href="mailto:comunicacaocorp@lwart.com.br">comunicacaocorp@lwart.com.br</a><br /></span> </>} />
          <Molde width="w-85" link="#" icone={Fale3} texto={<><span className="font-size-1125 font-Spinnaker">{parse(intl.formatMessage({ id: 'paginas.fale_com_a_gente.molde.molde9' }))}</span> <div className="d-flex mt-1 m-0" style={{ paddingRight: '5.2rem' }}><a href="https://www.instagram.com/lwartsolucoesambientais/" target="blank" className="bg-youtube rounded px-1"><img src={instagram} className="iconesRedeSociais" /></a><a href="https://www.facebook.com/lwartsolucoesambientais/" target="blank" className="bg-facebook rounded px-1"><img src={facebook} className="iconesRedeSociais" /></a><a href="https://www.linkedin.com/company/lwartsolucoesambientais" target="blank" className="bg-linkedin rounded px-1"><img src={linkedin} className="iconesRedeSociais w-90" /></a><a href="https://www.youtube.com/user/grupolwart" target="blank" className="bg-youtube rounded px-1"><img src={youtube} className="iconesRedeSociais w-85" /></a></div></>} />
        </div>
      </div>
      <div className="container d-block d-lg-none">
        <div className="row row-cols-3 mt-5 mb-5 m-0">
          <ModalFale wpp={whatsapp} icone={Fale4} texto={parse(intl.formatMessage({ id: 'paginas.fale_com_a_gente.molde_fale.molde_fale1' }))} info={<><div className="info"><span className="font-size-15 text-white">0800 701 0088</span></div> <div className="info"><span className="font-size-15"><a className="text-white" href="mailto:coleta@lwart.com.br">coleta@lwart.com.br</a></span></div>  </>} />
          {/* <ModalFale icone={gestaoResiduos} texto={parse(intl.formatMessage({ id: 'paginas.fale_com_a_gente.molde_fale.molde_fale2' }))} info={<><div className="info"><span className="font-size-15 text-white">0800 701 0088</span></div> <div className="info"><span className="font-size-12"><a className="text-white" href="mailto:gestaoderesiduos@lwart.com.br">gestaoderesiduos@lwart.com.br</a></span></div>  </>} /> */}
          <ModalFale icone={oleoBasico} texto={parse(intl.formatMessage({ id: 'paginas.fale_com_a_gente.molde_fale.molde_fale3' }))} info={<>{/* <div className="info"><span className="text-white font-size-15">(14)3269-5074</span></div> */}<div className="info"><a className="text-white font-size-12" href="mailto:comercial@lwart.com.br">comercial@lwart.com.br</a></div></>} />
          <ModalFale icone={fornecedor} texto={parse(intl.formatMessage({ id: 'paginas.fale_com_a_gente.molde_fale.molde_fale4' }))} info={<><div className="info"><span className="text-white"><a className="text-white font-size-12" target="blank" href="https://portaldecompraslwart.com/">portaldecompraslwart.com</a></span></div></>} />
          <ModalFale icone={Fale9} texto={parse(intl.formatMessage({ id: 'paginas.fale_com_a_gente.molde_fale.molde_fale5' }))} info={<><div className="info"><span className="text-white font-size-15 font-Spinnaker">(11) 95081-7522</span></div><div className="info"><span className="text-white"><a className="text-white font-size-12" href="mailto:lwart@pressaporter.com.br">lwart@pressaporter.com.br</a></span></div></>} />
          <ModalFale icone={Fale2} texto={parse(intl.formatMessage({ id: 'paginas.fale_com_a_gente.molde_fale.molde_fale6' }))} info={<div className="infoEndereco"><span className="text-white">Rod. Juliano Lorenzetti, 9 km<br /> + 270 m - Distrito Empresarial<br /> Luiz Trecenti - CEP 18685-900<br /> Lençóis Paulista - SP</span></div>} />
          <ModalFale icone={Fale1} texto={parse(intl.formatMessage({ id: 'paginas.fale_com_a_gente.molde_fale.molde_fale7' }))} info={<><div className="info"><span className="text-white font-size-15">(14) 3269-5000</span></div></>} />
          <ModalFale icone={Fale8} texto={parse(intl.formatMessage({ id: 'paginas.fale_com_a_gente.molde_fale.molde_fale8' }))} info={<>{/* <div className="info"><span className="font-size-15"><a className="text-white" href="mailto:mktcorp@lwart.com.br">mktcorp@lwart.com.br</a> </span></div> */}<div className="info"><span className="font-size-12"><a className="text-white" href="mailto:comunicacaocorp@lwart.com.br">comunicacaocorp@lwart.com.br</a></span></div></>} />
          <ModalFale icone={Fale3} texto={parse(intl.formatMessage({ id: 'paginas.fale_com_a_gente.molde_fale.molde_fale9' }))} info={<><RedeSocialMobile /></>} />
        </div>
      </div>
    </section>
    <div className="gradient-blue-to-green"></div>
  </>
}

export default FaleConosco

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/fale-com-a-gente/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  paralax: file(relativePath: {eq: "fale-conosco/falecomagente.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  paralaxMobile: file(
    relativePath: {eq: "fale-conosco/fale-com-gente-mobile.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
